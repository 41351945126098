<template>
	<main class="home">
		<alerts />
		<slideshow
			template="SlideshowSlider"
			section-title="Top Slider"
			:slideshow-id="2"
			:count="5"
		/>
		<championships
			class="bp-1024:padding-x-48 bp-1024:padding-b-32 bp-1024:padding-t-48"
			section-title="Featured Championships"
			template="ChampionshipsList"
			:featured="true"
		/>
		<slideshow
			template="SlideshowSliderAltParallax"
			section-title="Bottom Slider"
			:slideshow-id="4"
			:count="5"
		/>
	</main>
</template>

<script>
import Alerts from '@/components/Alerts';
import Slideshow from '@/components/slideshow/Slideshow';
import Championships from '@/components/championships/Championships';
import { get, sort_by } from '@/helpers';

export default {
	name: 'Home',

	components: {
		Alerts,
		Championships,
		Slideshow,
	},

	methods: {
		async get_settings() {
			var response = await fetch(
				`${this.$root.proxy}/services/championships.ashx/get_settings?type=home`,
			);
			var { data } = await response.json();

			return data;
		},
	},

	async created() {
		let settings = await this.get_settings();
		let title =
			settings.metadata &&
			settings.metadata.title &&
			settings.metadata.title.length > 0
				? settings.metadata.title
				: `NCAA Championship Tickets`;
		let description =
			settings.metadata &&
			settings.metadata.description &&
			settings.metadata.description.length > 0
				? settings.metadata.description
				: `Buy and sell NCAA Championship tickets for every game on the road to the NCAA title. Includes hospitality options, dates, schedules and locations.`;
		let image =
			settings.metadata &&
			settings.metadata.image &&
			settings.metadata.image.length > 0
				? this.$root.get_image_resizer_url('https://d230gea5qwdu36.cloudfront.net' + settings.metadata.image)
				: 'https://ncaatickets.com/img/share.jpg';

		title = `NCAA Championship Tickets`;
		description = `Buy and sell NCAA Championship tickets for every game on the road to the NCAA title. Includes hospitality options, dates, schedules and locations.`;
		image = 'https://ncaatickets.com/img/share.jpg';

		this.setMetaSidearm(title, [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'og:title',
				content: title,
			},
			{
				name: 'og:description',
				content: description,
			},
			{
				name: 'twitter:title',
				content: title,
			},
			{
				name: 'twitter:description',
				content: description,
			},
			{
				name: 'og:image',
				content: image,
			},
			{
				name: 'twitter:image',
				content: image,
			},
		]);
	},
};
</script>
